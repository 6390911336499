<template>
  <div>
    <query-form
      ref="queryRef"
      :query-form.sync="queryAward"
      :form="form"
    >
      <template v-slot:buttons>
        <b-button
          variant="primary"
          class="mr-1"
          @click="() => {currentPage = 1;search()}"
        >Search</b-button>
        <b-button
          variant="secondary"
          class="mr-1"
          @click="reset"
        >Reset</b-button>
        <b-button
          variant="success"
          class=""
          @click="download"
        >Download</b-button>
      </template>
    </query-form>
    <table-list
      :table-data.sync="tableData"
      :list.sync="tableList"
      :page-num.sync="currentPage"
      :total-list.sync="totalList"
    />

    <!-- Confirm Pay-out -->
    <b-modal
      ref="confirmPayout"
      centered
      title="Confirm Pay-out"
      hide-footer
    >
      <div class="confirm-payout">
        <div class="confirm-payout-p">
          Please ensure you have verified all KYC data before you continue to payout.
        </div>
        <div class="confirm-payout-p">
          This action cannot be undone.
        </div>
        <div class="btn-groups">
          <b-button
            class="mt-2 mb-2 mr-4"
            variant="outline-primary"
            @click="backFunc"
          >
            Back
          </b-button>
          <b-overlay
            :show="payoutLoading"
          >
            <b-button
              class="mt-2 mb-2"
              variant="primary"
              @click="payoutConfirm"
            >
              Pay-out
            </b-button>
          </b-overlay>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  defineComponent, ref, getCurrentInstance, computed, onBeforeMount, watch,
} from '@vue/composition-api'
import { BButton, BOverlay } from 'bootstrap-vue'
import { showToast } from '@/libs/utils'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const { $store } = proxy
    // query
    const queryAward = ref({})
    const form = ref()
    const groupOptions = ref([])
    onBeforeMount(async () => {
      proxy.$showLoading()
      const res = await proxy.$api.getGroupSelectList()
      const { code, data } = res.data
      if (code === 200) {
        const { list } = data
        groupOptions.value = list.map(value => ({ group_id: value.group_id, group_name: value.group_name }))
      }
      proxy.$hideLoading()

      form.value = [
        {
          label: 'Search Terms',
          itype: 'modeinput',
          imodel: 'searchTerms',
          placeholder: 'Enter search terms...',
          Options: [{ title: 'USER ID', value: '3' }, { title: 'REDEMPTION CODE', value: '11' }],
          labelKey: 'title',
          labelValue: 'value',
          defaultMode: { title: 'USER ID', value: '3' },
        },
        {
          label: 'Redemption status', itype: 'select', imodel: 'status', placeholder: 'Redemption status', Options: [{ status_id: '', status_name: 'All' }, { status_id: '1', status_name: 'CREATED' }, { status_id: '2', status_name: 'WAITING SUBMISSION ' }, { status_id: '3', status_name: 'PROCESSING' }, { status_id: '4', status_name: 'PAID-OUT' }].concat([]), labelKey: 'status_name', labelValue: 'status_id', defaultSelected: { status_id: '', status_name: 'All' },
        },
        {
          label: 'Time',
          itype: 'datetimeTerms',
          imodel: 'timeRangeDefault',
          placeholder: '',
          Options: [{ title: 'CREATE TIME', value: 'create_time' }, { title: 'UPDATE TIME', value: 'update_time' }],
          labelKey: 'title',
          labelValue: 'value',
          defaultMode: { title: 'CREATE TIME', value: 'create_time' },
        },
        {
          label: 'Code Value', itype: 'numberMaxMin', imodel: 'maxMin', placeholder: '', class: 'mt-1',
        },
        {
          label: 'KYC Status', itype: 'select', imodel: 'kycStatus', placeholder: 'KYC Status', Options: [{ kycstatus_id: '', kycstatus_name: 'All' }, { kycstatus_id: '2', kycstatus_name: 'Permanently blocked ' }, { kycstatus_id: '3', kycstatus_name: 'Auto-pass ' }, { kycstatus_id: '4', kycstatus_name: 'Verified' }, { kycstatus_id: '5', kycstatus_name: 'Re-upload request' }], labelKey: 'kycstatus_name', labelValue: 'kycstatus_id', class: 'mt-1', defaultSelected: { kycstatus_id: '', kycstatus_name: 'All' },
        },
        {
          label: 'Group Name', itype: 'select', imodel: 'groups', placeholder: 'Group Name', Options: [{ group_id: '', group_name: 'All' }].concat(groupOptions.value), labelKey: 'group_name', labelValue: 'group_id', class: 'mt-1', defaultSelected: { group_id: '', group_name: 'All' },
        },
        {
          label: 'Send Status', itype: 'select', imodel: 'sendStatus', placeholder: 'Send Status', Options: [{ status_id: '-1', status_name: 'All' }, { status_id: '2', status_name: 'Send' }, { status_id: '1', status_name: 'Not Send' }, { status_id: '3', status_name: 'Send Failure' }], labelKey: 'status_name', labelValue: 'status_id', class: 'mt-1', defaultSelected: { status_id: '-1', status_name: 'All' },
        },
      ]
    })
    const timeZone = computed(() => $store.state.wallet.timeZone, { immediate: true, deep: true })
    // table
    const currentPage = ref(1)
    const tableList = ref([])
    const totalList = ref(0)
    const currentData = ref(null)
    const payoutFunc = (type, gdata) => {
      if (type === 'payout') {
        currentData.value = gdata
        proxy.$refs.confirmPayout.show()
      }
    }
    const tableData = ref({
      tableColumns: [
        { key: 'bonus_code', label: 'Redemption Code' },
        { key: 'group_name', label: 'Group Name' },
        { key: 'send_status_text', label: 'send Status' },
        { key: 'send_email', label: 'send Email' },
        { key: 'send_name', label: 'send Name' },
        { key: 'user_id', label: 'User ID' },
        { key: 'amount', label: 'Code Value' },
        { key: 'currency', label: 'Currency' },
        { key: 'status_text', label: 'Redemption Status' },
        { key: 'created_time', label: 'Create Time' },
        { key: 'updated_time', label: 'Update Time' },
        { key: 'expiry_time', label: 'Expiry Time' },
        { key: 'kyc_status_text', label: 'KYC Status' },
        { key: 'operation', label: 'Pay Out' },
      ],
      templates: [
        { key: 'user_id', tType: 'prize_distribution_user_id' },
        { key: 'created_time', tType: 'prize_distribution_datetime' },
        { key: 'updated_time', tType: 'prize_distribution_datetime' },
        { key: 'expiry_time', tType: 'prize_distribution_datetime' },
        { key: 'operation', tType: 'prize_distribution_payout', callback: payoutFunc },
      ],
    })
    const getData = () => {
      const {
        searchTerms, status, timeRangeDefault, groups, kycStatus, maxMin, sendStatus,
      } = queryAward.value
      const params = {
        download: false,
        page_no: currentPage.value,
        page_size: 10,
      }
      if (searchTerms && searchTerms.searchKey) {
        params.key = {}
        params.key.key = searchTerms.searchKey
        params.key.model = Number(searchTerms.modeValue)
      }
      if (status && status.status_id) {
        params.status = Number(status.status_id)
      }
      if (maxMin) {
        if (maxMin.min !== '' && maxMin.max !== '') {
          params.amount_scope = {}
          params.amount_scope.min = `${Number(maxMin.min)}`
          params.amount_scope.max = `${Number(maxMin.max)}`
        }
      }
      if (kycStatus && kycStatus.kycstatus_id) {
        params.kyc_status = Number(kycStatus.kycstatus_id)
      }
      if (groups && groups.group_id) {
        params.group_id = groups.group_id
      }
      // queryRecordForm.value. {"timeRangeDefault":{"modeValue":"create_time","searchKey":[1668049200,1670900400]}}
      if (timeRangeDefault && timeRangeDefault.searchKey.length !== 0) {
        if (timeRangeDefault.modeValue === 'create_time') {
          params.created_time_scope = {};
          [params.created_time_scope.start_date, params.created_time_scope.end_date] = timeRangeDefault.searchKey
        } else if (timeRangeDefault.modeValue === 'update_time') {
          params.updated_time_scope = {};
          [params.updated_time_scope.start_date, params.updated_time_scope.end_date] = timeRangeDefault.searchKey
        } else {}
      }
      if (sendStatus && sendStatus.status_id) {
        params.send_status = Number(sendStatus.status_id)
      } else {
        params.send_status = -1
      }
      return params
    }
    // func
    const search = async () => {
      const params = getData()
      // console.log(params); return
      proxy.$showLoading()
      const res = await proxy.$api.fetchBonusList(params)
      proxy.$hideLoading()
      const { code, data, message } = res.data
      if (code === 200) {
        const { list, pagination } = data
        tableList.value = list
        totalList.value = pagination.total_count
        // showToast(proxy, 'Notification', 'Success', 'success')
      } else {
        showToast(proxy, 'Notification', `${message}`, 'danger')
      }
    }
    watch([currentPage], async () => { await search() })
    const reset = () => {
      // queryAward.value.searchKey = ''
      // queryAward.value.timeRangeDefault = []
      proxy.$refs.queryRef.reset()
    }
    const download = async () => {
      const params = getData()
      params.download = true
      // console.log(params); return
      proxy.$showLoading()
      const res = await proxy.$api.fetchBonusList(params)
      proxy.$hideLoading()
      const { code, data, message } = res.data
      if (code === 200) {
        // const { list, pagination } = data
        // tableList.value = list
        // totalList.value = pagination.total_count
        proxy.$router.push({ name: 'Download' })
      } else {
        showToast(proxy, 'Notification', `${message}`, 'danger')
      }
    }
    const backFunc = () => { proxy.$refs.confirmPayout.hide() }
    const payoutLoading = ref(false)
    const payoutConfirm = async () => {
      payoutLoading.value = true
      const { bonus_code } = currentData.value
      const res = await proxy.$api.bonusPayout({ bonus_code })
      payoutLoading.value = false
      const { code, message } = res.data
      if (code === 200) {
        search()
        proxy.$refs.confirmPayout.hide()
        showToast(proxy, 'Notification', 'Pay Out Success', 'success')
      } else {
        showToast(proxy, 'Notification', `${message}`, 'danger')
      }
    }
    return {
      queryAward,
      form,
      timeZone,
      tableData,
      tableList,
      currentPage,
      totalList,
      search,
      reset,
      download,
      backFunc,
      payoutLoading,
      payoutConfirm,
    }
  },
  components: {
    BButton, BOverlay,
  },
})
</script>
<style lang="scss">
.confirm-payout {
  padding: 0 20px;
  .confirm-payout-p {
    margin: 10px 0 0 10px;
    font-weight: 500;
    font-size: 16px;
  }
  .btn-groups {
    display: flex;
    justify-content: center;
  }
}
</style>
